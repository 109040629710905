import WidgetsMgr from 'widgets/widgetsMgr';
import HarmonyModal from 'harmony/global/HarmonyModal';
import Modal from 'widgets/global/Modal';
import cancelOrderModal from 'harmony/cancelOrderModal';
import AccessibilityFocusTrapMixin from 'widgets/global/AccessibilityFocusTrapMixin';
import ApothicHeader from './global/ApothicHeader';

WidgetsMgr.addWidgetsList('app_brand_apothic', () => [
    ['accessibilityFocusTrapMixin', AccessibilityFocusTrapMixin],
    ['modal', Modal, 'accessibilityFocusTrapMixin'],
    ['harmonyModal', HarmonyModal, 'modal'],
    ['cancelOrderModal', cancelOrderModal, 'harmonyModal'],
    ['apothicHeader', ApothicHeader],
]);
